import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import "./styles/globals.css";
import { Polygon } from "@thirdweb-dev/chains";
import { ThemeProvider, createTheme } from "@mui/material";

// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.

const container = document.getElementById("root");
const root = createRoot(container);

const theme = createTheme({
  typography: {
    fontFamily: 'Lilita One, Arial',
  },
})

root.render(
  <ThemeProvider theme={theme}>
  <React.StrictMode>
    <ThirdwebProvider
      activeChain={Polygon}
      clientId="1281d76cf6b180a329d018e5e03a8b1c"
    >
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
