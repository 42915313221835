import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        "@media (max-width: 600px)": {
          display: "none",
        },
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="white"
          indicatorColor="white"
        >
          <Tab
            label="Invest"
            {...a11yProps(0)}
            sx={{
              fontSize: "1.2rem",
              fontFamily: "League Spartan",
              letterSpacing: "0.29531rem",
            }}
          />
          <Tab
            label="How it works"
            {...a11yProps(1)}
            sx={{
              fontSize: "1.2rem",
              fontFamily: "League Spartan",
              letterSpacing: "0.1rem",
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
}
