import React, { useState } from "react";

function CustomSlider() {
  const [values, setValues] = useState([0, 25, 50, 75]);

  const handleSliderChange = (index, newValue) => {
    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);
  };

  return (
    <div className="custom-slider">
      {values.map((value, index) => (
        <input
          type="range"
          min="0"
          max="100"
          value={value}
          step="1"
          className="slider"
          key={index}
          onChange={(e) => handleSliderChange(index, e.target.value)}
        />
      ))}
    </div>
  );
}

export default CustomSlider;
