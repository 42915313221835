import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  // borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#CD8CFF" : "#308fe8",
  },
}));

export default function SliderComponent({ percentageVal = 0 }) {
  const [values, setValues] = useState([0, 50, 100]);

  return (
    <Box sx={{ width: "73%" }}>
      {/* <Slider
        value={values}
        marks
        min={0}
        max={100}
        sx={{
          color: "white",
        }}
      /> */}
      <BorderLinearProgress variant="determinate" value={percentageVal} />
    </Box>
  );
}
