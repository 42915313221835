import { Box, Button, Typography, InputBase } from "@mui/material";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { Web3Button } from "@thirdweb-dev/react";
import { useAddress } from "@thirdweb-dev/react";
import { useContract, useContractRead } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import Referals from "./referals";
import HowItWorks from "./Details/HowItWorks";
import Landing from "./LandingPage/landing";
import ReferalImage1 from "../assets/Rectangle 51.png";
import ReferalImage2 from "../assets/Rectangle 52.png";
function Main({ dataa, smartContractBalance, totalusers }) {
  // console.log(dataa);

  const inputRef = useRef(null);
  const [reffCode, setReffCode] = useState(""); // Initialize with 0 by default
  const location = useLocation();
  const tokenAddress = "0x63024EbcB59701ae7DDA0aC57B07A98b9f5698b9";
  const beeboxAddress = "0x71502c52e46B364227d57B6E7b502256179e6704";
  const address = useAddress();
  const { contract } = useContract(
    "0x71502c52e46B364227d57B6E7b502256179e6704"
  );
  const { data, isLoading } = useContractRead(contract, "owner", []);

  const [totalProfit, setTotalProfit] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [smartContractBalancee, setsmartContractBalance] = useState(0);
  const [referralIncome, setReferralIncome] = useState(0);

  const [investedAmount, setInvestedAmount] = useState(0);
  const [refferedBy, setRefferedBy] = useState("");

  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get("ref");

    if (refParam !== null) {
      setReffCode(refParam); // Convert the string to an integer
    } else {
      // getData();
      setReffCode(data);
    }
  }, [location.search, isLoading]);

  useEffect(() => {
    if (
      dataa !== undefined &&
      dataa.referredBy !== "0x0000000000000000000000000000000000000000"
    ) {
      let value = ethers.utils.formatEther(dataa.referralEarning.toString());
      value = parseFloat(value).toFixed(4);
      setReferralIncome(value);

      value = ethers.utils.formatEther(dataa.totalProfit.toString());
      value = parseFloat(value).toFixed(4);
      setTotalProfit(value);

      value = ethers.utils.formatEther(dataa.investedAmount.toString());
      value = parseFloat(value).toFixed(4);
      setInvestedAmount(value);

      setRefferedBy(dataa.referredBy);
    }
  }, [dataa]);

  function coptReferral() {
    const currentDomain = window.location.origin;
    const referralLink = `${currentDomain}/?ref=${address}`;
    const textarea = document.createElement("textarea");
    textarea.value = referralLink;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    alert(`Referral link "${referralLink}" copied to clipboard!`);
  }

  useEffect(() => {
    if (smartContractBalance !== undefined) {
      setsmartContractBalance(smartContractBalance);
    }
    if (totalusers !== undefined) {
      setTotalUsers(totalusers);
    }
  }, [smartContractBalance, totalusers]);

  return (
    <>
      <Landing />
      {/* <Box
        sx={{
          // minHeight: "9.5625rem",
          padding: "2rem",
          // backgroundColor: "#29261D",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          "@media (max-width: 600px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem 2rem",
            justifyContent: "center",
            "@media (max-width: 600px)": {
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "100%",
              justifyContent: "start",
              gap: "2rem",
              mb: "1rem",
            },
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: "2px",
              fontFamily: "League Spartan",
              letterSpacing: "0.0875rem",
              width: "464px",
            }}
          >
            {" "}
            <CircleIcon fontSize="small" sx={{ color: "#CD8CFF" }} />
            Total Users: {totalUsers}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: "2px",
              fontFamily: "League Spartan",
              letterSpacing: "0.0875rem",
              width: "464px",
            }}
          >
            {" "}
            <CircleIcon fontSize="small" sx={{ color: "#CD8CFF" }} />
            Smart Contract Total Balance: POLO {smartContractBalancee}
          </Typography>

          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: "2px",
              fontFamily: "League Spartan",
              letterSpacing: "0.0875rem",
              width: "464px",
            }}
          >
            {" "}
            <CircleIcon fontSize="small" sx={{ color: "#CD8CFF" }} />
            Total Profit: POLO {totalProfit}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: "2px",
              fontFamily: "League Spartan",
              letterSpacing: "0.0875rem",
              width: "464px",
            }}
          >
            {" "}
            <CircleIcon fontSize="small" sx={{ color: "#CD8CFF" }} />
            Raferral Earning: POLO {referralIncome}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "white",
              gap: "2px",
              fontFamily: "League Spartan",
              letterSpacing: "0.0875rem",
              width: "464px",
            }}
          >
            {" "}
            <CircleIcon fontSize="small" sx={{ color: "#CD8CFF" }} />
            Invested Amount: POLO {investedAmount}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "start",
              color: "white",
              gap: "2px",
              fontFamily: "League Spartan",
              letterSpacing: "0.0875rem",
              width: "464px",
            }}
          >
            {" "}
            <CircleIcon fontSize="small" sx={{ color: "#CD8CFF" }} />
            Referred By: {refferedBy}
          </Typography>
        </Box>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "3.5rem",
              minWidth: "19.27481rem",
              border: "3px solid #CD8CFF",
              color: "white",
              // backgroundColor: "#29261D",
              letterSpacing: "0.1rem",
              fontFamily: "League Spartan",
              "&:hover": {
                backgroundColor: "white",
                // color: "#29261D",
              },
              padding: "0.5rem",
              fontSize: "1.2rem",
              "@media (max-width: 600px)": {
                marginTop: "1.5rem",
              },
            }}
            onClick={coptReferral}
          >
            Copy My Referral Link
          </Button>
        </Box>
      </Box> */}

      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#100F0D",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "8rem",
          paddingY: "5rem",
          "@media (max-width: 600px)": {
            padding: "5rem",
            gap: "5rem",
          },
        }}
      >
        <Box
          sx={{
            minHeight: "15.6875rem",
            minWidth: "58.4375rem",
            // backgroundColor: "#29261D",
            borderRadius: "4.1875rem",
            border: "3px solid #CD8CFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            "@media (max-width: 600px)": {
              minHeight: "5.6875rem",
              minWidth: "18.4375rem",
              borderRadius: "1.4875rem",
              padding: "1rem",
              gap: "1rem",
            },
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
              "@media (max-width: 600px)": {
                "& > :not(style)": { m: 1 },
              },
            }}
            noValidate
            autoComplete="off"
            color={"white"}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#FFF",
                fontSize: "2.6rem",
                fontStyle: "normal",
                lineHeight: "normal",
                textAlign: "center",
                letterSpacing: "0.145rem",
                fontFamily: "League Spartan",
                "@media (max-width: 600px)": {
                  fontSize: "2rem",
                },
              }}
            >
              INVEST
            </Typography>
            <InputBase
              label="Enter Amount"
              variant="outlined"
              type="number"
              fullWidth
              placeholder="Enter Amount"
              color="warning"
              inputRef={inputRef}
              sx={{
                input: { color: "white" },
                border: "2px solid #CD8CFF",
                borderRadius: "10px",
                padding: "10px",
                paddingLeft: "20px",
                backgroundColor: "rgba(255,255,255,.1)",
              }}
            />
          </Box>
          {!isApproved && (
            <Web3Button
              className="walletbtn"
              contractAddress={tokenAddress}
              switchNetwork={5}
              action={async (contract) => {
                try {
                  const approvedAmount = await contract.call("allowance", [
                    address,
                    beeboxAddress,
                  ]);
                  const investAmount = ethers.utils.parseEther(
                    inputRef.current.value
                  );

                  if (approvedAmount >= investAmount) {
                    setIsApproved(true);
                  } else {
                    await contract.call("approve", [
                      beeboxAddress,
                      investAmount,
                    ]);
                    setIsApproved(true);
                  }
                } catch (error) {
                  console.error(error); // HANDLE ERROR HERE SARANG
                }
              }}
            >
              Approve
            </Web3Button>
          )}

          {isApproved && (
            <Web3Button
              contractAddress="0x71502c52e46B364227d57B6E7b502256179e6704"
              action={async (contract) => {
                console.log(reffCode);
                await contract.call("Invest", [
                  inputRef.current.value,
                  reffCode,
                ]);
              }}
            >
              Invest Now
            </Web3Button>
          )}
        </Box>

        {/*
          Withdraw Section Start
        */}

        <Box
          sx={{
            minHeight: "15.6875rem",
            minWidth: "58.4375rem",
            // backgroundColor: "#29261D",
            borderRadius: "4.1875rem",
            border: "3px solid #CD8CFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            padding: "1rem",
            "@media (max-width: 600px)": {
              minHeight: "5.6875rem",
              minWidth: "18.4375rem",
              borderRadius: "1.4875rem",
              padding: "1rem",
              gap: "1rem",
            },
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
              "@media (max-width: 600px)": {
                "& > :not(style)": { m: 1 },
              },
            }}
            noValidate
            autoComplete="off"
            color={"white"}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#FFF",
                fontSize: "2.6rem",
                fontStyle: "normal",
                lineHeight: "normal",
                textAlign: "center",
                letterSpacing: "0.145rem",
                fontFamily: "League Spartan",
                "@media (max-width: 600px)": {
                  fontSize: "2rem",
                },
              }}
            >
              WITHDRAW
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              "@media (max-width: 600px)": {
                flexDirection: "column",
              },
            }}
          >
            <Typography
              variant="span"
              sx={{
                color: "#FFF",
                fontSize: "1.5rem",
                fontStyle: "normal",
                lineHeight: "normal",
                textAlign: "center",
                letterSpacing: "0.145rem",
                fontFamily: "League Spartan",
                backgroundColor: "rgba(255,255,255,.1)",
                border: "1px solid #CD8CFF",
                padding: "10px 20px",
                borderRadius: "20px",
                margin: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              USERS : {totalUsers}
            </Typography>

            <Typography
              variant="span"
              sx={{
                color: "#FFF",
                fontSize: "1.5rem",
                fontStyle: "normal",
                lineHeight: "normal",
                textAlign: "center",
                letterSpacing: "0.145rem",
                fontFamily: "League Spartan",
                backgroundColor: "rgba(255,255,255,.1)",
                border: "1px solid #CD8CFF",
                padding: "10px 20px",
                borderRadius: "20px",
                margin: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              STAKED : POLO {smartContractBalancee}
            </Typography>

            <Typography
              variant="span"
              sx={{
                color: "#FFF",
                fontSize: "1.5rem",
                fontStyle: "normal",
                lineHeight: "normal",
                textAlign: "center",
                letterSpacing: "0.145rem",
                fontFamily: "League Spartan",
                backgroundColor: "rgba(255,255,255,.1)",
                border: "1px solid #CD8CFF",
                padding: "10px 20px",
                borderRadius: "20px",
                margin: "10px",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
              }}
            >
              REWARD : POLO {totalProfit}
            </Typography>
          </Box>
          {!isApproved && (
            <Web3Button
              className="walletbtn"
              contractAddress={tokenAddress}
              switchNetwork={5}
              action={async (contract) => {
                try {
                  const approvedAmount = await contract.call("allowance", [
                    address,
                    beeboxAddress,
                  ]);
                  const investAmount = ethers.utils.parseEther(
                    inputRef.current.value
                  );

                  if (approvedAmount >= investAmount) {
                    setIsApproved(true);
                  } else {
                    await contract.call("approve", [
                      beeboxAddress,
                      investAmount,
                    ]);
                    setIsApproved(true);
                  }
                } catch (error) {
                  console.error(error); // HANDLE ERROR HERE SARANG
                }
              }}
            >
              Approve
            </Web3Button>
          )}

          {isApproved && (
            <Web3Button
              contractAddress="0x71502c52e46B364227d57B6E7b502256179e6704"
              action={async (contract) => {
                console.log(reffCode);
                await contract.call("Invest", [
                  inputRef.current.value,
                  reffCode,
                ]);
              }}
            >
              Invest Now
            </Web3Button>
          )}
        </Box>

        {/*
          Withdraw section close
        */}

        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              marginTop: "auto",
              marginBottom: "auto",
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
          >
            <img
              sx={{
                height: "17px",
              }}
              src={ReferalImage2}
            ></img>
          </Box>
          <Typography
            sx={{
              color: "white",
              fontSize: "2.5rem",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              fontFamily: "League Spartan",
              letterSpacing: "0.96188rem",
              "@media (max-width: 600px)": {
                fontSize: "1.5rem",
              },
            }}
          >
            REFERRALS
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              marginBottom: "auto",
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
          >
            <img
              sx={{
                height: "17px",
              }}
              src={ReferalImage1}
            ></img>
          </Box>
        </Box>
        <Referals UserData={dataa} />
      </Box>
      {/*
          Referal Link Section Start
        */}

      <Box
        sx={{
          minHeight: "15.6875rem",
          minWidth: "58.4375rem",
          // backgroundColor: "#29261D",
          borderRadius: "4.1875rem",
          border: "3px solid #CD8CFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          padding: "1rem",
          marginX: "10rem",
          "@media (max-width: 600px)": {
            minHeight: "5.6875rem",
            minWidth: "18.4375rem",
            borderRadius: "1.4875rem",
            padding: "1rem",
            marginX: "2rem",
            gap: "1rem",
          },
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
            "@media (max-width: 600px)": {
              "& > :not(style)": { m: 1 },
            },
          }}
          noValidate
          autoComplete="off"
          color={"white"}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#FFF",
              fontSize: "2.6rem",
              fontStyle: "normal",
              lineHeight: "normal",
              textAlign: "center",
              letterSpacing: "0.145rem",
              fontFamily: "League Spartan",
              "@media (max-width: 600px)": {
                fontSize: "2rem",
              },
            }}
          >
            Referral Link
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          }}
        >
          <Button
            variant="h3"
            sx={{
              color: "#FFF",
              fontSize: "1.5rem",
              fontStyle: "normal",
              lineHeight: "normal",
              textAlign: "center",
              letterSpacing: "0.145rem",
              fontFamily: "League Spartan",
              backgroundColor: "rgba(255,255,255,.1)",
              border: "1px solid #CD8CFF",
              padding: "10px 20px",
              borderRadius: "20px",
              margin: "10px",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            }}onClick={coptReferral}
          >
            Copy
          </Button>

        </Box>
      </Box>

      <Box
        sx={
          {
            // backgroundColor: "#29261D",
          }
        }
      >
        <HowItWorks />
      </Box>
      {/* <Box
        sx={{
          minHeight: "40vh",
          padding: "10rem",
          gap: "8rem",
          "@media (max-width: 600px)": {
            padding: "2rem",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              marginTop: "auto",
              marginBottom: "auto",
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
          >
            <img
              sx={{
                height: "17px",
              }}
              src={ReferalImage2}
            ></img>
          </Box>
          <Typography
            sx={{
              color: "white",
              fontSize: "2.5rem",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              fontFamily: "League Spartan",
              letterSpacing: "0.96188rem",
              "@media (max-width: 600px)": {
                fontSize: "1.5rem",
              },
            }}
          >
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              marginBottom: "auto",
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
          >
            <img
              sx={{
                height: "17px",
              }}
              src={ReferalImage1}
            ></img>
          </Box>
        </Box>
      </Box> */}
    </>
  );
}

export default Main;