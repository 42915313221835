import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";
import Logo from "../../assets/POLOlpgo 2.png";
import SVVG from "../../assets/icon _ellipses_.svg";
import { useTheme } from "@mui/material/styles";
import WalletIcon from "@mui/icons-material/Wallet";
import useMediaQuery from "@mui/material/useMediaQuery";

function Landing() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        minHeight: "90vh",
        backgroundColor: "#100F0D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem",
        paddingX: "10rem",
        "@media (max-width: 600px)": {
          flexDirection: "column",
          justifyContent: "space-around",
          paddingX: "1rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          height: "100%",
          justifyContent: "center",
          flexDirection: "column",
          maxWidth: "50%",
          paddingRight: "10rem",
          gap: "2rem",
          "@media (max-width: 600px)": {
            maxWidth: "100%",
            padding: "2rem",
            gap: "2rem",
            textAlign: "center",
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#FFF",
            fontSize: "2.6rem",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "0.145rem",
            fontFamily: "League Spartan",
            "@media (max-width: 600px)": {
              fontSize: "2rem",
              width: "100%",
              textAlign: "center",
            },
          }}
        >
          #1 Investments On
          <br />
          Polygon Staking...
        </Typography>
        {/* <Image src={SVVG} alt="Image" /> */}
        <Typography
          variant="h2"
          sx={{
            color: "#FFF",
            fontSize: "1.575rem",
            fontStyle: "normal",
            fontWeight: "300",
            lineHeight: "normal",
            letterSpacing: "0.0875rem",
            fontFamily: "League Spartan",
          }}
        >
          Pololinks.org - a decentralized investment fund with an open smart
          contract.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
            "@media (max-width: 600px)": {
              width: "100%",
              justifyContent: "center",
              gap: "1.5rem",
            },
          }}
        >
          <Button className="walletbtn btnAudit">Audit</Button>
          <Button className="walletbtn btnAudit">Contract</Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "50%",
            "@media (max-width: 600px)": {
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            },
          }}
        >
          {/* {isSmallScreen && ( */}
          <Button
            // variant="contained"
            sx={{
              borderRadius: ".5rem",
              minWidth: "13.04069rem",
              backgroundColor: "#CD8CFF",
              color: "#2E2006",
              letterSpacing: "0.1875rem",
              fontSize: "1rem",
              "&:hover": {
                backgroundColor: "white",
              },
              // padding: "0.5rem",
            }}
          >
            Investments
          </Button>
          {/* )} */}
        </Box>
      </Box>
      <Box sx={{ position: "relative" }}>
        <LogoImage src={Logo} alt="Logo" />
      </Box>
    </Box>
  );
}
const LogoImage2 = styled("img")({
  width: "10.9355rem",
  height: "10.32769rem",
});
const Image = styled("img")({
  width: "1.8235rem",
  height: "0.45588rem",
  marginRight: 16,
});

const LogoImage = styled("img")({
  width: "513px",
  height: "570px",
  "@media (max-width: 600px)": {
    width: "15.0625rem",
    height: "13.625rem",
  },
});
export default Landing;
