import { Box, Typography, Slider, styled } from "@mui/material";
import React, { useState } from "react";
import Sliderr from "./slider";
import CustomSlider from "./custom-slider";
import lvlBackground from '../../assets/Rectangle 13.png';

function Cards() {
  const marks = [
    { value: 0, label: "0" },
    { value: 33.33, label: "1" },
    { value: 66.67, label: "2" },
    { value: 100, label: "3" },
  ];

  // const values = [0,33.33,66.67,100];
  const [values, setValues] = useState([0, 32, 66.67, 100]);

  function valuetext(value) {
    return `${value}°C`;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4rem",
        "@media (max-width: 600px)": {
          gap: "2.5rem",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            position: "absolute",
            minWidth: "40rem",
            maxHeight: "13.1875rem",
            "@media (max-width: 600px)": {
              minWidth: "20rem",
              minHeight: "5rem",
            },
          }}
        >
          <BackgroundImgLvl src={lvlBackground}></BackgroundImgLvl>
        </Box>
        <Box
          sx={{
            minWidth: "40rem",
            minHeight: "13.1875rem",
            // backgroundColor: "#29261D",
            // backgroundImage: `url(${lvlBackground})`,
            borderRadius: "2.0625rem",
            border: "3px solid #CD8CFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            position: "relative",
            zIndex: "2",
            "@media (max-width: 600px)": {
              minWidth: "9rem",
              minHeight: "5rem",
              borderRadius: "1.0625rem",
              padding: "0.5rem",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "enter",
              minWidth: "100%",
            }}
          >
            {" "}
            <Typography
              sx={{
                // color: "#CD8CFF",
                fontSize: "2.5rem",
                fontWeight: "800",
                fontFamily: "League Spartan",
                letterSpacing: "0.12rem",
                fontStyle: "normal",
                color: "white",
                "@media (max-width: 600px)": {
                  fontSize: "1.5rem",
                  fontWeight: "200",
                },
              }}
            >
              Level 1
            </Typography>
          </Box>
          <Sliderr percentageVal="7" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              minWidth: "100%",
              color: "white",
            }}
          >
            <Typography
              sx={{
                // color: "#CD8CFF",
                fontSize: "2rem",
                fontFamily: "League Spartan",
                fontWeight: "1000",
                letterSpacing: "0.12rem",
                fontStyle: "bold",
                color: "white",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                  fontWeight: "700",
                },
              }}
            >
              7%
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            position: "absolute",
            minWidth: "40rem",
            maxHeight: "13.1875rem",
            "@media (max-width: 600px)": {
              minWidth: "9rem",
              minHeight: "5rem",
            },
          }}
        >
          <BackgroundImgLvl src={lvlBackground}></BackgroundImgLvl>
        </Box>
        <Box
          sx={{
            minWidth: "40rem",
            minHeight: "13.1875rem",
            // backgroundColor: "#29261D",
            borderRadius: "2.0625rem",
            border: "3px solid #CD8CFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            position: "relative",
            "@media (max-width: 600px)": {
              minWidth: "20rem",
              minHeight: "5rem",
              borderRadius: "1.0625rem",
              padding: "0.5rem",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "enter",
              minWidth: "100%",
            }}
          >
            <Typography
              sx={{
                // color: "#CD8CFF",
                fontSize: "2.5rem",
                fontWeight: "800",
                fontFamily: "League Spartan",
                letterSpacing: "0.12rem",
                fontStyle: "normal",
                color: "white",
                "@media (max-width: 600px)": {
                  fontSize: "1.5rem",
                  fontWeight: "200",
                },
              }}
            >
              Level 2
            </Typography>
          </Box>
          <Sliderr percentageVal="3" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              minWidth: "100%",
              color: "white",
            }}
          >
            <Typography
              sx={{
                // color: "#CD8CFF",
                fontSize: "2rem",
                fontWeight: "1000",
                fontFamily: "League Spartan",
                letterSpacing: "0.12rem",
                fontStyle: "bold",
                color: "white",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                  fontWeight: "700",
                },
              }}
            >
              3%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const BackgroundImgLvl = styled("img")({
  maxHeight: "inherit",
  width: "95%",
});

export default Cards;
