import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
  InputBase,
  Slider,
} from "@mui/material";
import { toEther, Web3Button } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import Cards from "./Referals/cards";
function Referals({ UserData }) {
  const [value, setValue] = useState(10);
  // const { contract } = useContract(
  //   "0x3CBA93D40F492312083Af9633e8F6d44177939a5"
  // );
  // const address = useAddress();

  // const { data, isLoading } = useContractRead(contract, "Users", [address]);

  const [profit, setProfit] = useState(0);

  useEffect(() => {
    if (UserData !== undefined) {
      const timestamp = Math.floor(Date.now() / 1000);
      const lastWithdrawn = UserData.lastWithdrawTime.toString();
      const investedAmount = UserData.investedAmount.toString();
      const timePassed = timestamp - parseInt(lastWithdrawn, 10);
      const RATE_PER_SECOND = 231500; // 0.00000002315% * 1e8
      const DECIMALS = 1e13; // Scaling factor
      const totalProfit =
        (parseInt(investedAmount, 10) * RATE_PER_SECOND * timePassed) /
        DECIMALS;
      const ether = totalProfit/1e18
      
      //   const ether = ethers.utils.formatEther(totalProfit.toString()); // Convert Wei to Ether
      const reducedEther = parseFloat(ether).toFixed(4); // Reduce decimal places to 4
      setProfit(reducedEther);
    }
  }, [UserData]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4.6rem",
        "@media (max-width: 600px)": {
          flexDirection: "column",
          gap: "3rem",
        },
      }}
    >
      <Cards />
      <Box
        sx={{
          minHeight: "32.6375rem",
          minWidth: "24.625rem",
          // backgroundColor: "#29261D",
          borderRadius: "4.1875rem",
          border: "3px solid #CD8CFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "4rem",
          "@media (max-width: 600px)": {
            minHeight: "28.6375rem",
            minWidth: "14.625rem",
            borderRadius: "1.0625rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          },
        }}
      >
        <Box sx={{}}></Box>
        <Typography
          variant="h6"
          sx={{
            color: "#CD8CFF",
            fontSize: "2.7375rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            fontFamily: "League Spartan",
            letterSpacing: "0.145rem",
            textAlign: "center",
            "@media (max-width: 600px)": {
              fontSize: "2.4375rem",
            },
          }}
        >
          POLO <br />
          {profit}
        </Typography>
        <Box
          sx={{
            width: 300,
            "@media (max-width: 600px)": {
              width: 200,
            },
          }}
        ></Box>
        <Typography
          variant="h6"
          sx={{
            color: "#CD8CFF",
            fontSize: "2rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            textAlign: "center",
            fontFamily: "League Spartan",
            letterSpacing: "0.145rem",
            maxWidth: "50%",
            "@media (max-width: 600px)": {
              fontSize: "1.4375rem",
            },
          }}
        >
          AVAILABLE REWARDS
        </Typography>

        <Web3Button
          className="walletbtn"
          contractAddress="0x71502c52e46B364227d57B6E7b502256179e6704"
          action={async (contract) => {
            await contract.call("withdraw", []);
          }}
        >
          Withdraw
        </Web3Button>
      </Box>
    </Box>
  );
}

export default Referals;
