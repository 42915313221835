import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

export default function Card({
  image,
  title,
  description,
  rowReverse = false,
}) {
  return (
    <Box
      sx={{
        // maxWidth: "23.1875rem",
        display: "flex",
        flexDirection: rowReverse ? "row-reverse" : "row",
        alignItems: "center",
        // borderRight: "1px solid white",
        paddingX: "5rem",
        paddingY: "1rem",
        gap: "2rem",
        "@media (max-width: 600px)": {
          paddingY: "1rem",
          paddingX: "1rem",
          flexDirection: "column",
          gap: 0,
        },
      }}
    >
      <StyledStack>
        <Image src={image} alt="" />
      </StyledStack>
      <Typography
        sx={{
          color: "#FFFF",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "0.145rem",
          fontFamily: "League Spartan",
          fontSize: " 2.0625rem",
          textAlign: "justify",
          paddingTop: "0rem",
          "@media (max-width: 600px)": {
            fontSize: "1.3rem",
            textAlign: "left",
            // border: "1px solid #CD8CFF",
            borderTop: 0,
            padding: "1rem",
          },
        }}
      >
        {description}
      </Typography>
    </Box>
  );
}

const Image = styled("img")({
  width: "250px",
  height: "250px",
  marginRight: "5rem",
  "@media (max-width: 600px)": {
    marginRight: 0,
    width: "150px",
    height: "150px",
    // border: "1px solid #CD8CFF",
    padding: "0.7rem",
  },
});

const StyledStack = styled(Stack)({
  alignItems: "center",
    flexDirection: "column",
  padding: "2rem",
  "@media (max-width: 600px)": {
    alignItems: "start",

    flexDirection: "row",
  },
});
