import {
  AppBar,
  Box,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import Tabs from "./tabs";
import Logo from "../assets/POLOlpgo 3.png";
import WalletIcon from "@mui/icons-material/Wallet";
import { IconButton } from "@mui/material";
import { ConnectWallet } from "@thirdweb-dev/react";
function Navbar() {
 
  return (
    <StyledAppbar position="static" elevation={0}>
      <StyledContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LogoImage src={Logo} alt="Logo" />
          <Typography
            variant="h3"
            sx={{
              letterSpacing: "0.51563rem",
              fontWeight: "400",
              fontSize: "2rem",
              fontFamily: "League Spartan",
              "@media (max-width: 600px)": {
                fontSize: "1rem",
                letterSpacing: "0.1rem",
              },
            }}
          >
            Investments
          </Typography>
        </Box>
        <Stack
          direction="row"
          spacing={2.5}
          alignItems="center"
          sx={{ justifyContent: "space-between", width: "43%" }}
        >
          <Tabs />
          <Box
            sx={{
              marginLeft: "0px!important",
            }}
          >
            <ConnectWallet
              className="walletbtn-sm"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "small!important",
                  marginLeft: "0px!important",
                },
              }}
              dropdownPosition={{
                side: "bottom",
                align: "center",
              }}
              detailsBtn={() => {
                return (
                  <IconButton sx={{ color: "#CD8CFF" }}>
                    <WalletIcon
                      sx={{
                        fontSize: "large",
                        "@media (max-width: 600px)": {
                          fontSize: "small",
                        },
                      }}
                    />
                  </IconButton>
                );
              }}
            />
          </Box>
        </Stack>
      </StyledContainer>
    </StyledAppbar>
  );
}

const StyledAppbar = styled(AppBar)(() => ({
  height: "6rem",
  backgroundColor: "#1f062e",
}));

const StyledContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
});

const LogoImage = styled("img")({
  width: "4.75rem",
  height: "4.5rem",
  marginRight: 16,
  "@media (max-width: 600px)": {
    width: "2.75rem",
    height: "2.5rem",
  },
});

export default Navbar;
