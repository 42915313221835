import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Logo from "../assets/POLOlpgo 3.png";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane, FaEnvelope } from "react-icons/fa";
import { SiTelegram } from "react-icons/si";
function footer() {
  return (
    <Box>
      <StyledAppbar position="static" elevation={0}>
        <StyledContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoImage src={Logo} alt="Logo" />
            <Stack spacing={0.5}>
              <Typography
                variant="h5"
                fontWeight={400}
                sx={{
                  lineHeight: "normal",
                  letterSpacing: "0.51563rem",
                  fontWeight: "400",
                  fontSize: "2rem",
                  fontFamily: "League Spartan",
                  "@media (max-width: 600px)": {
                    fontSize: "0.8rem",
                  },
                }}
              >
                Pololinks
                <br />
                Investments
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "1.1rem",
                  display: "none",
                  "@media (max-width: 600px)": {
                    fontSize: "0.5rem",
                    display: "block",
                  },
                }}
              >
                All Rights Reserved 2023.
              </Typography>
            </Stack>
          </Box>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Open Sans",
              fontSize: "1.1rem",
              "@media (max-width: 600px)": {
                fontSize: "0.5rem",
                display: "none",
              },
            }}
          >
            All Rights Reserved 2023.
          </Typography>
          <StyledIconsContainer>
            <StyledIconButton
              onClick={() =>
                window.open("https://twitter.com/pololinks1", "_blank")
              }
            >
              <BsTwitter />
            </StyledIconButton>
            <StyledIconButton
              onClick={() => window.open("https://t.me/pololinkschannel", "_blank")}
            >
              <FaTelegramPlane />
            </StyledIconButton>
            <StyledIconButton
              onClick={() =>
                window.open("mailto:support@pololinks.org", "_blank")
              }
            >
              <FaEnvelope />
            </StyledIconButton>
          </StyledIconsContainer>
        </StyledContainer>
      </StyledAppbar>
    </Box>
  );
}

const StyledAppbar = styled(AppBar)(() => ({
  height: "6rem",
  backgroundColor: "#1f062e",
}));

const StyledContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
});

const LogoImage = styled("img")({
  width: "80px",
  height: "79px",
  marginRight: 16,
  "@media (max-width: 600px)": {
    width: "2.5rem",
    height: "2.25rem",
  },
});

const StyledIconButton = styled(IconButton)({
  color: "#CD8CFF",
  height: "2em",
  width: "2em",
  "@media (max-width: 600px)": {
    height: "1.1em",
    width: "1.1em",
  },
});

const StyledIconsContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  "@media (max-width: 600px)": {
  },
});

export default footer;
