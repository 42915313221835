import { Box, Typography } from "@mui/material";
import React from "react";
import Finance from "../../assets/finance.png";
import Deposit from "../../assets/deposit.png";
import Secured from "../../assets/secured.png";
import Verified from "../../assets/verified.png";

import Card from "./CardNew";
function HowItWorks() {
  const details = [
    {
      id: 1,
      image: Finance,
      title: "CRYPTO WALLET",
      rowReverse: true,
      description:
        "The Most sustainable and fully DECENTRALIZED 100% support model, based on Smart Contract technology.",
    },
    {
      id: 2,
      image: Secured,
      title: "SMART CONTRACT",
      description: "It is the most reasonable, safest, and best cutting-edge financial SUPPORT model available at this time.",
    },
    {
      id: 3,
      image: Deposit,
      title: "INVESTMENT PROFIT",
      rowReverse: true,
      description:
        "Enter POLOLINKS by depositing a minimum of 2000 POLO to the Smart Contract - every 24 hours you will receive your daily ROI. Reinvest when you receive 200% of your deposit.",
    },
    {
      id: 4,
      image: Verified,
      title: "REFERRAL REWARD",
      description:
        "POLOLINKS is Security Audited and 100% verified on POLYGON blockchain.",
    },
  ];
  return (
    <Box
      sx={{
        border: "3px solid #CD8CFF",
        margin: "10rem",
        borderRadius: "4.1875rem",
        justifyContent: "center",
        "@media (max-width: 600px)": {
          margin: "2rem",
        },
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#CD8CFF",
            fontSize: "3.625rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            fontFamily: "League Spartan",
            letterSpacing: "0.145rem",
            textTransform: "uppercase",
            textAlign: "center",
            marginTop: "5rem",
            "@media (max-width: 600px)": {
              fontSize: "2rem",
              fontWeight: "400",
              minWidth: "15rem",
            },
          }}
        >
          how it works ?
        </Typography>
      </Box>
      <Box
        sx={{
          justifyContent: "space-around",
          // paddingTop: "10rem",
          // marginLeft: "4rem",
          "@media (max-width: 600px)": {
            alignItems: "center",
            marginLeft: 0,
            paddingTop: "3rem",
            flexDirection: "column",
          },
        }}
      >
        {details.map((item) => (
          <Card
            key={item.id}
            image={item.image}
            description={item.description}
            title={item.title}
            rowReverse={item.rowReverse}
          />
        ))}
      </Box>
    </Box>
  );
}

export default HowItWorks;
